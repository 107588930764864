import React from "react"
import PricingNavbar from "../Pricing/PricingNavbar/PricingNavbar"
import "../../styles/aboutUs/AboutUs.css"
import monkslLogo from "../../../static/assets/restlessmonkslogo.png"
import abhinav from "../../../static/assets/team/abhinav.png"
import aman from "../../../static/assets/team/aman.png"
import dheeraj from "../../../static/assets/team/dheeraj.png"
import kabir from "../../../static/assets/team/kabir.png"
import darin from "../../../static/assets/team/darin.png"
import tanushree from "../../../static/assets/team/tanushree.png"
import smallMonkLogo from "../../../static/assets/restlessmonkslogo2.png"
import {HiExternalLink} from 'react-icons/hi'
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter"
function AboutUs() {
  const data = [
    {
      monkTitle: `CEO`,
      monkImage: `${abhinav}`,
      monkName: `abhinav rai`,
    },
    {
      monkTitle: "CTO",
      monkImage: `${aman}`,
      monkName: `aman kumar`,
    },
    {
      monkTitle: "Design Head",
      monkImage: `${darin}`,
      monkName: `darin menezes`,
    },
    {
      monkTitle: "Marketing",
      monkImage: `${kabir}`,
      monkName: `kabir rai`,
    },
    {
      monkTitle: "Product designer",
      monkImage: `${tanushree}`,
      monkName: `tanushree nath`,
    },
    {
      monkTitle: "Product Engineer",
      monkImage: `${dheeraj}`,
      monkName: `dheeraj s`,
    },
  ]
  return (
    <div className="aboutUsCol">
      <PricingNavbar />
      <div className="container is-fluid aboutUsContainer">
        <div className="columns is-multiline is-mobile">
          <div className="column is-12 aboutUsHeaderCol">
            <h2 className="has-text-black is-size-3-desktop aboutUsHeader">
              We are
            </h2>
            <img
              src={monkslLogo}
              className="image ml-4 restlessMonksLogo"
              loading="lazy"
              alt="restlessmonkslogo"
              title="restlessmonkslogo"
            />
          </div>

          {data.map((monk, key) => {
            return (
              <div
                key={key}
                className="column has-text-centered mt-5 monkBox is-2-desktop is-4-tablet is-6-mobile"
              >
                <p className="has-text-black is-uppercase monkTitle">
                  {monk.monkTitle}
                </p>
                <div className="monkImageBox mt-3">
                  <img
                    src={`${monk.monkImage}`}
                    alt="memberImage"
                    title="memberImage"
                    loading="lazy"
                    className="image monkImage"
                  />
                </div>
                <p className="has-text-black monkName is-uppercase mt-4">
                  {monk.monkName}
                </p>
              </div>
            )
          })}
          <div className="column is-8-desktop is-10-tablet is-12-mobile is-offset-1-tablet  is-offset-2-desktop has-text-centered mt-5">
            <h2 className="has-text-black is-size-3-desktop is-size-4-tablet is-size-4-mobile aboutMonksHeader">
              About Us
            </h2>
            <p className="has-text-black is-size-6  monkAboutText px-5">
              We are an agile team wearing multiple hats.
              <br />
              <br />
              We are in influencer space since past 3 years. Having built multiple automation tools in the past for influencers and having talked with multiple brands, we realised
              a big gap and massive need for automation in this space.
              <br />
              <br />
              We are all in for this and are bootstrapping. We are a lean team and believe in power of feedback. Let us know if you need any feature or integration or if anything is not working.
              We are active on support all the time and would love to hear everything ~ The good, bad, ugly, everything!

            </p>
          </div>
        </div>
        <div className="columns is-multiline is-mobile mt-6">
          <div className="column is-12-mobile is-12-tablet is-10-desktop is-offset-1-desktop aboutRestlessMonksBox">
            <img src={smallMonkLogo} alt="restlessMonksSmallLogo"  />
            <p className="has-text-white has-text-centered-mobile is-size-6 aboutRestlessMonksInfo">
            <b>Agile Monks Building Micro SaaS Products <span className="is-hidden-mobile">|</span></b> <span className="is-hidden-mobile">Come Visit Us!</span>
            </p>
            <p className="has-text-white is-size-6 aboutRestlessMonksInfo is-hidden-tablet">Come Visit Us!</p>
            <a href="https://restlessmonks.com/" target="_blank" className="restlessmonksLink">
                restlessmonks.com <HiExternalLink/>
            </a>
           
          </div>
        </div>
      </div>
      <div className="mt-5">
          <InfluencerFooter/>
      </div>
    </div>
  )
}

export default AboutUs
