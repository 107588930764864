import React from 'react'
import AboutUs from '../../components/AboutUs/AboutUs'
import PageSeo from '../../components/SEO'

function index() {
    return (
        <>
            <PageSeo pageTitle="Who Are We"/>
            <AboutUs/>
        </>
    )
}

export default index
